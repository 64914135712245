<template>
  <b-container>
    <div class="return d-none d-md-block">
            <router-link to="/directories"> Back to Directories</router-link>

    </div>

    <div class="text-center my-5">
      <MTBLoader v-if="directoryItems.length === 0" />
    </div>

    <OutpostsGrid v-if="directoryItems.length !== 0" :directory="directory" :items="directoryItems" ref="grid" />
  </b-container>
</template>

<script>
import OutpostsGrid from "@/components/Report/OutpostsGrid";
import axios from "axios";
import util from "../support/util";
import MTBLoader from "@/components/MTBLoader";

export default {
  data() {
    return {
      directory: null,
      directoryItems: [],
    };
  },
  components: { OutpostsGrid, MTBLoader },
  created() {
    util.scrollTop();
    this.$store.dispatch("loadReports").catch((e) => {
      console.log(e);
    });
    this.$store.dispatch("loadDirectories").then(() => {
      if (this.$store.state.directories.length !== 0) {
        this.directory = this.$store.state.directories.find((directory) => {
          return directory.slug === this.$route.params.name;
        });
      }
      if (this.directory) {
        const payload = { baseId: this.directory.airtable.baseId, tableName: this.directory.airtable.tableName, view: this.directory.airtable.view };
        this.loadData(payload);
      }
    });
  },
  methods: {
    loadData(payload) {
      // var t0 = performance.now()
      // console.log(payload);
      axios
        .get("https://us-central1-mtb-research.cloudfunctions.net/getAirtableData", {
          params: {
            baseId: payload.baseId,
            tableName: payload.tableName,
            view: payload.view,
          },
        })
        .then((res) => {
          // var t1 = performance.now()
          // console.log("Call to load VUEX  took " + (t1 - t0)/1000 + " seconds.")
          let items = res.data.slice().sort((a, b) => (a["Outpost Name"] > b["Outpost Name"] ? 1 : -1));
          // console.log(items);
          this.directoryItems = items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.return {
  position: absolute;
  top: 3vh;
  left: 4vw;
  color: #0275d8;
  z-index: 1 !important;
}
</style>
