<template>
  <b-container>
    <div class="text-center my-5">
      <MTBLoader v-if="items.length === 0" />
    </div>

    <b-row v-if="items.length !== 0">
      <b-col>
        <b-img
          class="shadow-sm"
          style="
            min-height: 35vh;
            border-radius: 25px;
            filter: brightness(0.85);
          "
          fluid-grow
          :src="directory.banner"
        ></b-img>
        <div class="title-banner">
          <h1 style="font-size: calc(10px + 2.3vw)">
            {{ directory.title.split("-")[0] }}
          </h1>
          <!-- <h3 style="font-size: calc(10px + 1vw);">Outpost Directory</h3> -->
          <!-- <b-button :to="{ name: 'Report', params: { name: directory.reportSlug } }" target="_blank"  class="mt-3" size="sm" href="" pill variant="primary" ><v-icon class="mr-2" name="download"  scale="1" /> Download Report</b-button> -->
        </div>
      </b-col>
    </b-row>

    <b-row v-if="directory.map" class="justify-content-end">
      <b-col class="text-right mt-3" cols="2">
        <b-form-checkbox v-model="mapView" name="switch-view" switch>
          View Map
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- SEARCH -->
    <b-row v-if="items.length !== 0" class="mt-3">
      <b-col md="6" class="mx-auto">
        <b-form-input
          border-variant="light"
          class="search-bar shadow mb-2"
          type="search"
          v-model="search"
          :placeholder="
            $props.directory.type == 'government'
              ? 'Search Organization...'
              : 'Search Company...'
          "
        ></b-form-input>
      </b-col>
    </b-row>

    <!-- FILTERS -->
    <b-row v-if="items.length !== 0">
      <b-col>
        <!-- <b-form-checkbox v-model="mapView" name="switch-view" switch>
          View Map
        </b-form-checkbox> -->
        <b-button
          variant="light"
          size="sm"
          v-b-toggle.show-filters
          pill
          class="mt-3 mb-2"
          ><b-icon icon="search" variant="primary"></b-icon> Advanced
          Filters</b-button
        >

        <p v-if="filteredItems.length !== 1" class="text-primary">
          {{ filteredItems.length }} Innovation Outposts
        </p>
        <p
          v-if="
            filteredItems.length !== items.length && filteredItems.length === 1
          "
          class="text-primary"
        >
          {{ filteredItems.length }} Innovation Outpost
        </p>
      </b-col>
    </b-row>

    <b-collapse id="show-filters">
      <b-row v-if="items.length !== 0" class="mt-3 mb-4">
        <b-col v-if="$props.directory.type !== 'government'" md="3">
          <b-form-select
            v-model="optionVertical"
            class="search-bar shadow mb-2"
            :options="optionVerticalsComputed"
          ></b-form-select>
        </b-col>

        <!-- <b-col md="4" v-if="$props.directory.type==='government'" class=""> -->
        <b-col md="3" class="">
          <b-form-select
            v-model="optionRegion"
            class="search-bar shadow mb-2"
            :options="optionRegionsComputed"
          ></b-form-select>
        </b-col>

        <b-col md="3" class="">
          <b-form-select
            v-model="optionCountry"
            class="search-bar shadow mb-2"
            :options="optionCountriesComputed"
          ></b-form-select>
        </b-col>

        <b-col md="3" v-if="$props.directory.type !== 'government'" class="">
          <b-form-select
            v-model="optionType"
            class="search-bar shadow mb-2"
            :options="optionTypeList"
          ></b-form-select>
        </b-col>

        <b-col md="3" v-if="$props.directory.type === 'government'" class="">
          <b-form-select
            v-model="optionLocation"
            class="search-bar shadow mb-2"
            :options="optionLocationComputed"
          ></b-form-select>
        </b-col>
      </b-row>
    </b-collapse>

    <!-- MAP VIEW -->
    <b-row v-if="mapView">
      <b-col>
        <Map :items="filteredItems" :directory="$props.directory" />
      </b-col>
    </b-row>

    <div v-if="!mapView" class="mt-3">
      <!-- CARDS GRID -->
      <b-row v-if="$props.directory.type == 'corporate'">
        <b-col class="mt-2" md="12">
          <div class="my-grid">
            <b-card
              id="grid-card"
              v-for="(item, index) in paginatedCards"
              v-bind:item="item"
              v-bind:key="index"
              v-bind:data-index="index"
              style="max-width: 20rem"
              border-variant="light"
              class="mb-2 rounded shadow cards-grid"
            >
              <b-card-img
                class="mb-4"
                :src="item['Logo'] ? item['Logo'][0]['url'] : noImage"
                top
              ></b-card-img>

              <h5 class="mb-2">{{ item["Outpost Name"] || "" }}</h5>
              <h6 class="mb-4 text-secondary">
                {{ item["Corporate Name"][0] || "" }}
              </h6>

              <b-card-text>
                <p>
                  <b-icon icon="lightning" class="mr-2 text-primary"></b-icon
                  >{{ item["Outpost Type"] || "" }}
                </p>
                <p v-if="item['Industry'] && item['Industry'].length > 0">
                  <b-icon icon="gear" class="mr-2 text-primary"></b-icon>
                  {{ item["Industry"][0] }}
                </p>
                <p>
                  <b-icon icon="geo-alt" class="mr-2 text-primary"></b-icon>
                  {{ item["Outpost HQ City"] || "" }}
                </p>
                <a
                  v-if="item['Website']"
                  :href="item['Website']"
                  target="__blank"
                  ><b-icon icon="link45deg" class="mr-2"></b-icon> Website</a
                >
              </b-card-text>

              <div class="mt-4">
                <span
                  >{{ item["Full Name"] ? item["Full Name"][0] : "" }}
                  <a
                    v-if="item['LinkedIn URL']"
                    :href="item['LinkedIn URL'][0]"
                    target="_blank"
                  >
                    <v-icon name="brands/linkedin-in" class="ml-1" scale="1" />
                  </a>
                </span>
                <p>
                  <small class="text-muted">
                    {{ item["Title"] ? item["Title"][0] : "" }}
                  </small>
                </p>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>

      <!-- CARDS GRID -->
      <b-row v-if="$props.directory.type == 'government'">
        <b-col class="mt-2" md="12">
          <div class="my-grid">
            <b-card
              id="grid-card"
              v-for="(item, index) in paginatedCards"
              v-bind:item="item"
              v-bind:key="index"
              v-bind:data-index="index"
              style="max-width: 20rem"
              border-variant="light"
              class="mb-2 rounded shadow cards-grid"
            >
              <b-card-img
                class="mb-4"
                :src="item['Logo'] ? item['Logo'][0]['url'] : noImage"
                top
              ></b-card-img>
              <h5 class="mb-2">{{ item["Outpost Name"] || "" }}</h5>
              <h6 class="mb-4 text-secondary">
                {{ item["Organization Name"] || "" }}
              </h6>

              <b-card-text>
                <p>
                  <b-icon icon="lightning" class="mr-2 text-primary"></b-icon
                  >{{ item["Organization Type"] || "" }}
                </p>
                <!-- <p><b-icon icon="gear" class="mr-2 text-primary"></b-icon> {{ item["Industry"][0] }}</p> -->
                <p>
                  <b-icon icon="geo-alt" class="mr-2 text-primary"></b-icon>
                  {{ item["Billing City"] || "" }}
                </p>
                <a
                  v-if="item['Website']"
                  :href="item['Website']"
                  target="__blank"
                  ><b-icon icon="link45deg" class="mr-2"></b-icon> Website</a
                >
              </b-card-text>

              <div class="mt-4">
                <span
                  >{{ item["Full Name"] || "" }}
                  <a
                    v-if="item['LinkedIn URL']"
                    :href="item['LinkedIn URL']"
                    target="_blank"
                  >
                    <v-icon name="brands/linkedin-in" class="ml-1" scale="1" />
                  </a>
                </span>
                <p>
                  <small class="text-muted"> {{ item["Title"] || "" }} </small>
                </p>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>

      <!-- PAGINATION -->
      <b-row v-if="items.length > 0">
        <b-col>
          <div class="mt-3">
            <b-pagination
              @page-click="scrollTop"
              pills
              class="mt-5"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import MTBLoader from "@/components/MTBLoader";
import util from "@/support/util";
import Map from "@/components/Charts/Map";
export default {
  name: "OutpostGrid",
  components: {
    MTBLoader,
    Map,
  },
  props: ["items", "directory"],
  data() {
    return {
      mapView: false,
      currentPage: 1,
      perPage: 24,
      search: "",
      noImage:
        "https://firebasestorage.googleapis.com/v0/b/mtb-platform.appspot.com/o/logo%2Fdefault.png?alt=media&token=4b2a4994-bb36-4717-8672-ef72fab08782",
      optionVertical: null,
      optionCountry: null,
      optionRegion: null,
      optionType: null,
      optionLocation: null,
      optionTypeList: [
        { value: null, text: "All Types" },
        "Corporate Innovation Antenna",
        "Corporate Innovation Lab",
        "Corporate R&D Center",
        "CVC",
      ],
    };
  },
  computed: {
    // items() {
    //   return this.$props.state.items;
    // },
    directoryType() {
      return this.$props.directory.type;
    },
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      return this.filteredItems.slice(start, end);
    },
    rows() {
      return this.filteredItems.length;
    },
    filteredItems() {
      return this.filterItemsSearch.filter(
        (x) =>
          this.filterItemsVertical.includes(x) &&
          this.filterItemsCountry.includes(x) &&
          this.filterItemsType.includes(x) &&
          this.filterItemsRegion.includes(x) &&
          this.filterItemsLocation.includes(x)
      );
    },
    filterItemsVertical() {
      return this.filterItemByVertical(this.items);
    },
    filterItemsCountry() {
      return this.filterItemByCountry(this.items);
    },
    filterItemsRegion() {
      return this.filterItemByRegion(this.items);
    },
    filterItemsLocation() {
      return this.filterItemByLocation(this.items);
    },
    filterItemsType() {
      return this.filterItemByType(this.items);
    },
    filterItemsSearch() {
      return this.filterItembySearch(this.items);
    },
    optionCountriesComputed() {
      var countriesList = [];
      if (this.$props.directory.type == "corporate") {
        this.items.forEach((item) => {
          if (item["Corporate HQ Country"]) {
            countriesList.indexOf(item["Corporate HQ Country"][0]) === -1
              ? countriesList.push(item["Corporate HQ Country"][0])
              : null;
          }
        });
      } else if (this.$props.directory.type == "government") {
        this.items.forEach((item) => {
          if (item["Organization HQ Country"]) {
            countriesList.indexOf(item["Organization HQ Country"]) === -1
              ? countriesList.push(item["Organization HQ Country"])
              : null;
          }
        });
      }

      countriesList.sort();
      countriesList.unshift({ value: null, text: "All Countries" });
      return countriesList;
    },

    optionRegionsComputed() {
      var countriesList = [];
      if (this.$props.directory.type == "corporate") {
        this.items.forEach((item) => {
          if (item["Corporate HQ Region"]) {
            countriesList.indexOf(item["Corporate HQ Region"][0]) === -1
              ? countriesList.push(item["Corporate HQ Region"][0])
              : null;
          }
        });
      } else if (this.$props.directory.type == "government") {
        this.items.forEach((item) => {
          if (item["Organization HQ Region"]) {
            countriesList.indexOf(item["Organization HQ Region"]) === -1
              ? countriesList.push(item["Organization HQ Region"])
              : null;
          }
        });
      }
      countriesList.sort();
      countriesList.unshift({ value: null, text: "All Regions" });
      return countriesList;
    },
    optionLocationComputed() {
      var locationList = [];
      if (this.$props.directory.type == "government") {
        this.items.forEach((item) => {
          if (item["Billing City"]) {
            locationList.indexOf(item["Billing City"]) === -1
              ? locationList.push(item["Billing City"])
              : null;
          }
        });
      }
      locationList.sort();
      locationList.unshift({ value: null, text: "All Locations" });
      return locationList;
    },
    optionVerticalsComputed() {
      var verticalsList = [];
      this.items.forEach((item) => {
        if (item["Industry"]) {
          verticalsList.indexOf(item["Industry"][0]) === -1
            ? verticalsList.push(item["Industry"][0])
            : null;
        }
      });
      verticalsList.sort();
      verticalsList.unshift({ value: null, text: "All Industries" });
      return verticalsList;
    },
  },
  created() {
    // this.noImage = util.noImage;
    this.loadLogo = util.loadLogo;
    this.scrollTop = util.scrollTop;
  },
  methods: {
    filterItembySearch(items) {
      if (this.$props.directory.type == "corporate") {
        return items.filter((item) => {
          let corporate =
            item["Corporate Name"] && item["Corporate Name"][0]
              ? item["Corporate Name"][0].toLowerCase()
              : "";
          let outpost = item["Outpost Name"]
            ? item["Outpost Name"].toLowerCase()
            : "";
          return (
            corporate.includes(this.search.toLowerCase()) ||
            outpost.includes(this.search.toLowerCase())
          );
        });
      } else if (this.$props.directory.type == "government") {
        return items.filter((item) => {
          let organizationName = item["Organization Name"]
            ? item["Organization Name"].toLowerCase()
            : "";
          let outpostName = item["Outpost Name"]
            ? item["Outpost Name"].toLowerCase()
            : "";
          return (
            organizationName.includes(this.search.toLowerCase()) ||
            outpostName.includes(this.search.toLowerCase())
          );
        });
      }
    },
    filterItemByType(items) {
      if (this.optionType) {
        if (this.$props.directory.type == "corporate") {
          return items.filter((item) => {
            // Check if 'Outpost Type' exists and is not null or undefined before checking if it includes optionType
            return item["Outpost Type"]
              ? item["Outpost Type"].includes(this.optionType)
              : false;
          });
        } else if (this.$props.directory.type == "government") {
          return items.filter((item) => {
            // Check if 'Organization Type' exists and is not null or undefined before checking if it includes optionType
            return item["Organization Type"]
              ? item["Organization Type"].includes(this.optionType)
              : false;
          });
        }
      } else {
        return this.items;
      }
    },
    filterItemByVertical(items) {
      if (this.optionVertical && this.$props.directory.type !== "government") {
        return items.filter((item) => {
          return item["Industry"]
            ? item["Industry"].includes(this.optionVertical)
            : false;
        });
      } else {
        return this.items;
      }
    },
    filterItemByCountry(items) {
      if (this.optionCountry) {
        if (this.$props.directory.type == "corporate") {
          return items.filter((item) => {
            return item["Corporate HQ Country"]
              ? item["Corporate HQ Country"].includes(this.optionCountry)
              : false;
          });
        } else if (this.$props.directory.type == "government") {
          return items.filter((item) => {
            return item["Organization HQ Country"]
              ? item["Organization HQ Country"].includes(this.optionCountry)
              : false;
          });
        }
      } else {
        return this.items;
      }
    },
    filterItemByRegion(items) {
      if (this.optionRegion) {
        return items.filter((item) => {
          return item["Organization HQ Region"]
            ? item["Organization HQ Region"].includes(this.optionRegion)
            : false;
        });
      } else {
        return this.items;
      }
    },
    filterItemByLocation(items) {
      if (this.optionLocation) {
        if (this.$props.directory.type == "government") {
          return items.filter((item) => {
            return item["Billing City"]
              ? item["Billing City"].includes(this.optionLocation)
              : false;
          });
        }
      } else {
        return this.items;
      }
    },
  },
};
</script>

<style scoped>
.my-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}
.my-grid > * {
  width: 100%;
  max-width: 20rem;
}
.search-bar {
  border-radius: 10px;
  border: none;
}
.card:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}
#grid-card .card:hover::after {
  opacity: 1;
}
.card {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card-img-top {
  margin-top: 2vw;
  width: 100%;
  height: 4vw;
  object-fit: scale-down;
  max-width: 12rem;
  min-height: 6rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.cardUnlocked {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* background-color: rgb(0, 0, 0); */
  border: none;
}

.cardUnlocked .card-title#currentSummitTitle {
  color: white;
  font-size: 2.3rem;
  min-height: 300px;
}

#banner .card-title {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filterBar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-banner {
  z-index: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#banner {
  position: relative;
  text-align: center;
  color: white;
}

.search-banner {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
